import { getVisualDevInfo, Update, Create } from '@/api/onlineDev/visualDev'
import { getDataSourceListAll } from '@/api/systemData/dataSource'
import {FlowEngineList} from '@/api/workFlow/FlowEngine'
import commonMixin from '@/mixins/generator/common'
export default {
  mixins: [commonMixin],
  data() {
    return {
      visible: false,
      loading: false,
      activeStep: 0,
      dataForm: {
        id: '',
        fullName: '',
        enCode: '',
        type: 1,
        webType: 2,
        linkId: '',
        sortCode: 0,
        enableFlow: 0,
        state: 1,
        category: '',
        description: "",
        mainTable: ''
      },
      dataRule: {
        fullName: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        enCode: [
          { required: true, message: '编码不能为空', trigger: 'blur' },
        ],
        category: [
          { required: true, message: '分类不能为空', trigger: 'change' },
        ]
      },
      stepLoading: false,
      maxStep: 2,
      mainTable: [],
      defaultTable: [],
      formVisible: false,
      btnLoading: false,
      formData: null,
      columnData: null,
      appColumnData: null,
      dbOptions: [],
      dbType: "MySQL",
      mainTableFields: [],
      relationTable: "",
      flowPathData:[]
    }
  },
  methods: {
    init( id, type, webType, isToggle) {
      this.activeStep = 0
      this.mainTable = []
      this.defaultTable = []
      this.dataForm.id = id || ''
      this.getDbOptions()
      // 流程接口
      // this.getFlowEngineList();  //开发测试中
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          this.loading = true;
          getVisualDevInfo(this.dataForm.id).then(res => {
            this.dataForm = res.data.data
            this.dataForm.webType = this.dataForm.webType || 2
            if (isToggle) this.dataForm.webType = webType
            this.maxStep = parseInt(this.dataForm.webType)
            if (this.maxStep > 2) this.maxStep = 2
            this.formData = this.dataForm.formData && JSON.parse(this.dataForm.formData)
            this.columnData = this.dataForm.columnData && JSON.parse(this.dataForm.columnData)
            this.appColumnData = this.dataForm.appColumnData && JSON.parse(this.dataForm.appColumnData)
            this.mainTable = this.dataForm.mainTable && JSON.parse(this.dataForm.mainTable) || []
            this.defaultTable = this.dataForm.mainTable && JSON.parse(this.dataForm.mainTable) || []
            this.updateFields()
          }).catch(() => { this.loading = false })
        } else {
          this.getdplinkList();
          this.dataForm = {};
          this.dataForm.type = type
          this.dataForm.webType = webType || 2
          this.maxStep = parseInt(this.dataForm.webType)
        }
      })
    },
    getFlowEngineList() {
      FlowEngineList().then((res) => {
        this.flowPathData = res.data.data;
      })
    },
    getdplinkList() {
      getDataSourceListAll().then((res) => {
        this.dataForm.linkId = res.data.data[0].id;
      })
    },
    dataFormSubmit() {
      const getData = () => {
        this.dataForm.mainTable = JSON.stringify(this.mainTable);
        this.dataForm.formData = this.formData ? JSON.stringify(this.formData) : null
        this.dataForm.columnData = this.columnData ? JSON.stringify(this.columnData) : null
        this.dataForm.appColumnData = this.appColumnData ? JSON.stringify(this.appColumnData) : null
      }
      if (!this.activeStep) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            getData()
            this.formSubmit()
          }
        })
      } else if (this.activeStep == 1) {
        this.$refs['generator'].getData().then(res => {
          this.formData = res.formData
          getData()
          this.formSubmit()
        }).catch(err => {
          err.msg && this.$message.warning(err.msg)
        })
      } else if (this.activeStep == 2) {
        this.$refs['columnDesign'].getData().then(res => {
          this.columnData = res.columnData
          this.appColumnData = res.appColumnData
          getData()
          this.formSubmit()
        }).catch(err => {
          err.msg && this.$message.warning(err.msg)
        })
      }
    },
    formSubmit() {
      this.btnLoading = true
      // this.dataForm.columnData = JSON.parse(this.dataForm.columnData);
      // if (this.dataForm.columnData.btnsList > 0) {
      //   this.dataForm.columnData.btnsList.forEach((item) => {
      //     item.func = item.func.replace(/&amp;/g, "&")
      //   })
      // }
      // if (this.dataForm.columnData.columnBtnsList > 0) {
      //   this.dataForm.columnData.columnBtnsList.forEach((item) => {
      //     item.func = item.func.replace(/&amp;/g, "&")
      //   })
      // }
      // this.dataForm.columnData = JSON.stringify(this.dataForm.columnData);
      Create(this.dataForm).then((res) => {
        this.$message({
          message: res.data.msg,
          type: 'success',
          onClose: () => {
            this.closeDialog(true)
            this.$parent.gridHeadSearch();
            this.btnLoading = false
          }
        })
      }).catch(() => { this.btnLoading = false })
    },
    next() {
      if (this.activeStep < 1) {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.getDbType()
            const type = this.dataForm.type
            if (!this.mainTable.length) {
              if (this.defaultTable.length || type == 3 || type == 4) {
                this.$message.warning('请至少选择一个数据表')
                return
              }
              this.$store.commit('SET_TABLE', false)
              this.$store.commit('SET_ALLTABLE', [])
              this.$store.commit('UPDATE_FORMITEM_LIST', [])
              this.activeStep += 1
            } else {
              if (!this.exist()) return
              let subTable = this.mainTable.filter(o => o.typeId == '0')
              this.$store.commit('UPDATE_SUB_TABLE', subTable)
              this.$store.commit('SET_ALLTABLE', this.mainTable)
              this.$store.commit('SET_DATABASE', this.dataForm.linkId)
              this.$store.commit('SET_TABLE', true)
              this.$store.commit('UPDATE_FORMITEM_LIST', this.mainTableFields)
              this.activeStep += 1
            }
          }
        })
      } else if (this.activeStep == 1) {
        this.$refs['generator'].getData().then(res => {
          this.formData = res.formData
          this.activeStep += 1
        }).catch(err => {
          err.msg && this.$message.warning(err.msg)
        })
      } else {
        this.$refs['columnDesign'].getData().then(res => {
          this.columnData = res.columnData
          this.appColumnData = res.appColumnData
          this.activeStep += 1
        }).catch(err => {
          err.msg && this.$message.warning(err.msg)
        })
      }
    }
  }
}
